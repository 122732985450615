// import React from 'react'
// import Hero from '../../components/Hero/Hero'
import DataTable from './DataTable'; // Import your DataTable component
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import logo from './logo_img.png';
import React, { useState, useEffect, useRef } from 'react';
import { BsFiletypeSql } from "react-icons/bs";
import Navbar from './Navbar'
import Footer from './components/Footer';

const question_list = [
    {
        "id": 1,
        "Question": "Highest price from all the orders",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/1` }}>Click here</Link>
    },
    {
        "id": 2,
        "Question": "Average price of all the orders up to two decimals",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/2` }}>Click here</Link>
    },
    {
        "id": 3,
        "Question": "Different payment methods available",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/3` }}>Click here</Link>
    },
    {
        "id": 4,
        "Question": "Orders with the status - shipped",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/4` }}>Click here</Link>
    },
    {
        "id": 5,
        "Question": "Payment method Credit card, status as processed in year 2021",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/5` }}>Click here</Link>
    },
    {
        "id": 6,
        "Question": "Status as processed, payment method as Debit Card, price above 10 during July 2021",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/6` }}>Click here</Link>
    },
    {
        "id": 7,
        "Question": "orders having more than two order items",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/7` }}>Click here</Link>
    },
    {
        "id": 8,
        "Question": "Month with most Card based payment orders",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/8` }}>Click here</Link>
    },
    {
        "id": 9,
        "Question": "Payment method wise order items count",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/9` }}>Click here</Link>
    },
    {
        "id": 10,
        "Question": "How many orders have the item - Avocado Salad",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/10` }}>Click here</Link>
    },
    {
        "id": 11,
        "Question": "Ratio of Orders per unique customer",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/11` }}>Click here</Link>
    },
    {
        "id": 12,
        "Question": "payment_method wise orders count for - Green Leaf Lettuce",
        "Difficulty": "Easy",
        "Link": <Link to={{ pathname: `question/12` }}>Click here</Link>
    },
    {
        "id": 13,
        "Question": "Orders having top 3 highest aggregated weights",
        "Difficulty": "Medium",
        "Link": <Link to={{ pathname: `question/13` }}>Click here</Link>
    },
    {
        "id": 14,
        "Question": "Count of shipped orders as % of processed orders - payment_method wise",
        "Difficulty": "Medium",
        "Link": <Link to={{ pathname: `question/14` }}>Click here</Link>
    },
    {
        "id": 15,
        "Question": "Find the %YoY growth of GDP",
        "Difficulty": "Medium",
        "Link": <Link to={{ pathname: `question/15` }}>Click here</Link>
    },
    {
        "id": 16,
        "Question": "products which were sold for more than 6 months(any)",
        "Difficulty": "Medium",
        "Link": <Link to={{ pathname: `question/16` }}>Click here</Link>
    },
    {
        "id": 17,
        "Question": "Second last order in July 2020",
        "Difficulty": "Medium",
        "Link": <Link to={{ pathname: `question/17` }}>Click here</Link>
    },
    {
        "id": 18,
        "Question": "older in age than their immediate predecessor?",
        "Difficulty": "Medium",
        "Link": <Link to={{ pathname: `question/18` }}>Click here</Link>
    },
    {
        "id": 19,
        "Question": "3rd president having the second most common occupation?",
        "Difficulty": "Hard",
        "Link": <Link to={{ pathname: `question/19` }}>Click here</Link>
    },
    {
        "id": 20,
        "Question": "Longest streak of any political party?",
        "Difficulty": "Hard",
        "Link": <Link to={{ pathname: `question/20` }}>Click here</Link>
    }
    // ,
    // {
    //     "id": 50,
    //     "Question": "Payment method wise order items count",
    //     "Difficulty": "Easy",
    //     "Link": <Link to={{ pathname: `question/50` }}>Click here</Link>
    // }

]

const integrations = [
    {
        title: "New to SQL",
        desc: "Learn Basic SQL",
        icon: <svg
            viewBox="0 0 1024 1024"
            fill="currentColor"
            height="3em"
            width="3em"
        >
            <defs>
                <style />
            </defs>
            <path d="M301.3 496.7c-23.8 0-40.2-10.5-41.6-26.9H205c.9 43.4 36.9 70.3 93.9 70.3 59.1 0 95-28.4 95-75.5 0-35.8-20-55.9-64.5-64.5l-29.1-5.6c-23.8-4.7-33.8-11.9-33.8-24.2 0-15 13.3-24.5 33.4-24.5 20.1 0 35.3 11.1 36.6 27h53c-.9-41.7-37.5-70.3-90.3-70.3-54.4 0-89.7 28.9-89.7 73 0 35.5 21.2 58 62.5 65.8l29.7 5.9c25.8 5.2 35.6 11.9 35.6 24.4.1 14.7-14.5 25.1-36 25.1z" />
            <path d="M928 140H96c-17.7 0-32 14.3-32 32v496c0 17.7 14.3 32 32 32h380v112H304c-8.8 0-16 7.2-16 16v48c0 4.4 3.6 8 8 8h432c4.4 0 8-3.6 8-8v-48c0-8.8-7.2-16-16-16H548V700h380c17.7 0 32-14.3 32-32V172c0-17.7-14.3-32-32-32zm-40 488H136V212h752v416z" />
            <path d="M828.5 486.7h-95.8V308.5h-57.4V534h153.2zm-298.6 53.4c14.1 0 27.2-2 39.1-5.8l13.3 20.3h53.3L607.9 511c21.1-20 33-51.1 33-89.8 0-73.3-43.3-118.8-110.9-118.8s-111.2 45.3-111.2 118.8c-.1 73.7 43 118.9 111.1 118.9zm0-190c31.6 0 52.7 27.7 52.7 71.1 0 16.7-3.6 30.6-10 40.5l-5.2-6.9h-48.8L542 491c-3.9.9-8 1.4-12.2 1.4-31.7 0-52.8-27.5-52.8-71.2.1-43.6 21.2-71.1 52.9-71.1z" />
        </svg>

    }
    // , {
    //     title: "Github",
    //     desc: "Ut enim ad minim veniam",
    //     icon: <svg className="w-10 h-10" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <g clip-path="url(#clip0_694_1831)">
    //             <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0005 1C18.303 1.00296 12.7923 3.02092 8.45374 6.69305C4.11521 10.3652 1.23181 15.452 0.319089 21.044C-0.593628 26.636 0.523853 32.3684 3.47174 37.2164C6.41963 42.0643 11.0057 45.7115 16.4099 47.5059C17.6021 47.7272 18.0512 46.9883 18.0512 46.36C18.0512 45.7317 18.0273 43.91 18.0194 41.9184C11.3428 43.3608 9.93197 39.101 9.93197 39.101C8.84305 36.3349 7.26927 35.6078 7.26927 35.6078C5.09143 34.1299 7.43223 34.1576 7.43223 34.1576C9.84455 34.3275 11.1123 36.6194 11.1123 36.6194C13.2504 40.2667 16.7278 39.2116 18.0949 38.5952C18.3095 37.0501 18.9335 35.999 19.621 35.4023C14.2877 34.8017 8.68408 32.7548 8.68408 23.6108C8.65102 21.2394 9.53605 18.9461 11.156 17.2054C10.9096 16.6047 10.087 14.1785 11.3905 10.8829C11.3905 10.8829 13.4054 10.2427 17.9916 13.3289C21.9253 12.2592 26.0757 12.2592 30.0095 13.3289C34.5917 10.2427 36.6026 10.8829 36.6026 10.8829C37.9101 14.1706 37.0875 16.5968 36.8411 17.2054C38.4662 18.9464 39.353 21.2437 39.317 23.6187C39.317 32.7824 33.7015 34.8017 28.3602 35.3905C29.2186 36.1334 29.9856 37.5836 29.9856 39.8122C29.9856 43.0051 29.9578 45.5736 29.9578 46.36C29.9578 46.9962 30.391 47.7391 31.6071 47.5059C37.0119 45.7113 41.5984 42.0634 44.5462 37.2147C47.4941 32.3659 48.611 26.6326 47.6972 21.0401C46.7835 15.4476 43.8986 10.3607 39.5587 6.68921C35.2187 3.01771 29.7067 1.00108 24.0085 1H24.0005Z" fill="#191717" />
    //             <path d="M9.08887 35.264C9.03721 35.3826 8.84645 35.4181 8.69146 35.3351C8.53646 35.2522 8.42122 35.098 8.47686 34.9755C8.5325 34.853 8.71928 34.8214 8.87428 34.9044C9.02927 34.9874 9.14848 35.1455 9.08887 35.264Z" fill="#191717" />
    //             <path d="M10.0626 36.3428C9.98028 36.384 9.88612 36.3955 9.79622 36.3753C9.70632 36.3551 9.62629 36.3045 9.56979 36.2321C9.41479 36.0662 9.38298 35.837 9.50221 35.7342C9.62143 35.6315 9.83606 35.6789 9.99105 35.8449C10.146 36.0108 10.1818 36.24 10.0626 36.3428Z" fill="#191717" />
    //             <path d="M11.0085 37.7139C10.8614 37.8167 10.6111 37.7139 10.472 37.5085C10.4335 37.4716 10.4029 37.4274 10.382 37.3785C10.3611 37.3296 10.3503 37.2771 10.3503 37.2239C10.3503 37.1708 10.3611 37.1183 10.382 37.0694C10.4029 37.0205 10.4335 36.9763 10.472 36.9394C10.619 36.8406 10.8694 36.9394 11.0085 37.141C11.1476 37.3425 11.1516 37.6112 11.0085 37.7139Z" fill="#191717" />
    //             <path d="M12.2921 39.0417C12.161 39.1879 11.8947 39.1484 11.6761 38.9509C11.4575 38.7533 11.4059 38.4846 11.537 38.3423C11.6682 38.2001 11.9344 38.2396 12.161 38.4332C12.3875 38.6268 12.4312 38.8995 12.2921 39.0417Z" fill="#191717" />
    //             <path d="M14.0923 39.8162C14.0327 40.0019 13.7625 40.0849 13.4922 40.0058C13.222 39.9268 13.0432 39.7055 13.0948 39.5158C13.1465 39.3262 13.4207 39.2392 13.6949 39.3262C13.9691 39.4131 14.144 39.6225 14.0923 39.8162Z" fill="#191717" />
    //             <path d="M16.0557 39.9506C16.0557 40.1442 15.8331 40.3102 15.547 40.3141C15.2608 40.3181 15.0264 40.16 15.0264 39.9664C15.0264 39.7728 15.2489 39.6068 15.535 39.6029C15.8212 39.5989 16.0557 39.753 16.0557 39.9506Z" fill="#191717" />
    //             <path d="M17.8838 39.6463C17.9196 39.84 17.7208 40.0415 17.4347 40.0889C17.1486 40.1363 16.8982 40.0217 16.8624 39.8321C16.8267 39.6424 17.0333 39.4369 17.3115 39.3855C17.5897 39.3342 17.848 39.4527 17.8838 39.6463Z" fill="#191717" />
    //         </g>
    //         <defs>
    //             <clipPath id="clip0_694_1831">
    //                 <rect width="48" height="48" fill="white" />
    //             </clipPath>
    //         </defs>
    //     </svg>

    // }
]


const Home = () => {

    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef();

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures that the effect runs only once on mount


    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuVisible(false);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex h-screen overflow-hidden justify-center ">


            {isSmallScreen ? (
                // Render this div for small screens

                <div className="flex flex-col h-screen">

                    <div className="navbar bg-base-100 ">
                        <div className="navbar-start items-center">
                            <Link to="/" className="items-center flex flex-row">
                                <img src={logo} alt="Logo" className="w-0 sm:mx-auto" />
                            </Link>
                        </div>

                    </div>

                    {/* in the above code change the w-0 to show the original width to w-64 */}


                    {/* <div className="z-10 fixed top-2 w-full px-4 sm:px-6 md:hidden"> */}
                    <div className="z-30 fixed top-2 left-1/2 transform -translate-x-1/2 w-full max-w-lg px-4 sm:px-6 md:hidden">

                        <div className="relative z-50 flex h-12 items-center justify-between rounded-3xl border border-slate-200 bg-slate-100/60 pl-5 pr-1.5 shadow-lg shadow-black/5 backdrop-blur-lg">
                            {/* SQL Guroo */}

                            <div className="flex justify-start xl:flex-1">
                                <a href="/">
                                    {/* <span className="sr-only">Glide</span> */}
                                    {/* SQL Guroo */}

                                    <h1 className="text-black   font-display font-bold leading-tight text-2xl  max-w-2xl">



                                        <div className="mt-2 mb-2 font-semibold">
                                            <p className="flex justify-start text-2xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">
                                                SQL Guroo
                                            </p>
                                        </div>


                                    </h1>


                                </a>
                            </div>

                            <a href="/">


                            </a>
                            <div className="absolute -bottom-px h-px w-8 overflow-y-hidden">
                                <div className="mx-auto -mt-6 h-12 w-1/3 bg-white opacity-80 blur" />
                            </div>

                            {!menuVisible && (
                                <div className="flex space-x-2">
                                    <button
                                        className="relative h-9 flex-shrink-0 rounded-full bg-gradient-to-b from-slate-100 to-slate-200  p-px shadow shadow-black/5 focus:outline-none focus:ring-1 focus:ring-white/20"
                                        type="button" onClick={() => setMenuVisible(!menuVisible)}
                                    >
                                        <div className="flex h-full items-center space-x-2 rounded-full bg-[#F9FAFB] text-slate-800 hover:bg-white  font-bold  px-4">
                                            <span className="">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    className="fill-current h-5"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6ZM2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12ZM8 18C8 17.4477 8.44772 17 9 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H9C8.44772 19 8 18.5523 8 18Z"
                                                        fill="current"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            )}


                            {menuVisible && (
                                <div className="flex space-x-2">
                                    <button
                                        className="relative h-9 flex-shrink-0 rounded-full bg-gradient-to-b from-slate-100 to-slate-200  p-px shadow shadow-black/5 focus:outline-none focus:ring-1 focus:ring-white/20"
                                        type="button"
                                    >
                                        <div className="flex h-full items-center space-x-2 rounded-full bg-[#F9FAFB] text-slate-800 hover:bg-white  font-bold  px-4">
                                            <span className="">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    className="fill-current h-5"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                                        fill="current"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            )}


                        </div>
                    </div>


                    {/* Drop down for mobile */}

                    {menuVisible && (

                        <div>

                            <div className="z-20 fixed inset-0 bg-white/40 backdrop-blur-lg " />


                            <div ref={menuRef} className="z-50 fixed inset-x-4 mt-2 flex flex-col items-center justify-between rounded-3xl border border-slate-200 bg-slate-200/50 p-4 py-6 shadow-lg shadow-black/5  sm:inset-x-6">
                                <ul className="relative flex flex-col sm:flex-row items-center justify-center gap-4 mb-6">
                                    <li className="relative" to="/learnsql">
                                        <Link to="/">
                                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/10 focus:outline-none  text-slate-600 hover:text-black">
                                                Home
                                            </button>
                                        </Link>
                                    </li>
                                    <li className="relative">
                                        <Link to="/learnsql/1">
                                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/10 focus:outline-none text-slate-600 hover:text-black ">
                                                Learn SQL
                                            </button>
                                        </Link>
                                    </li>
                                    <li className="relative">
                                        <Link to="/question/1">
                                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/10 focus:outline-none text-slate-600 hover:text-black ">
                                                Practice SQL
                                            </button>
                                        </Link>
                                    </li>
                                    <li className="relative">
                                        <Link to="/pricing">
                                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/10 focus:outline-none text-slate-600 hover:text-black ">
                                                Pricing
                                            </button>
                                        </Link>
                                    </li>
                                    <li className="relative">
                                        <Link to="https://sqlguroo.com/blog/blog">
                                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/10 focus:outline-none text-slate-600 hover:text-black ">
                                                Blog
                                            </button>
                                        </Link>
                                    </li>
                                </ul>


                                <button
                                    className="relative h-9 flex-shrink-0 rounded-full bg-gradient-to-b from-indigo-400 to-indigo-500 p-px shadow shadow-indigo-500/25 focus:outline-none focus:ring-1 focus:ring-white/30"
                                    type="button"
                                >
                                    <Link to="/login">
                                        <div className="flex h-full items-center space-x-2 rounded-full bg-indigo-500 text-indigo-50 hover:bg-[#7375EE] font-bold  pl-4 pr-2.5">
                                            <span className="">Login</span>
                                            <svg
                                                height={10}
                                                viewBox="0 0 20 10"
                                                className="fill-current opacity-50  text-white"
                                                fill="current"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0.5 5C0.5 4.44772 0.947715 4 1.5 4L18.5 4C19.0523 4 19.5 4.44772 19.5 5C19.5 5.55229 19.0523 6 18.5 6L1.5 6C0.947715 6 0.5 5.55228 0.5 5Z"
                                                    fill="current"
                                                    opacity={0}
                                                    transform-origin="10px 5px"
                                                    style={{ transform: "scaleX(0)", transformOrigin: "10px 5px" }}
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M15.2071 0.292893C14.8166 -0.0976311 14.1834 -0.0976311 13.7929 0.292893C13.4024 0.683418 13.4024 1.31658 13.7929 1.70711L17.0858 5L13.793 8.29274C13.4025 8.68327 13.4025 9.31643 13.793 9.70696C14.1836 10.0975 14.8167 10.0975 15.2073 9.70696L19.2071 5.70711L19.2073 5.70696C19.5978 5.31643 19.5978 4.68327 19.2073 4.29274C19.2023 4.28781 19.1974 4.28294 19.1924 4.27814L15.2071 0.292893Z"
                                                    fill="current"
                                                    transform-origin="16.500100135803223px 4.99993222951889px"
                                                    style={{
                                                        transform: "translateX(-7px)",
                                                        transformOrigin: "16.5001px 4.99993px"
                                                    }}
                                                />
                                            </svg>
                                        </div>
                                    </Link>
                                </button>
                            </div>

                        </div>
                    )
                    }



                    <div className=" z-0 flex flex-col h-full justify-items-center items-center  border border-gray-200 rounded-md bg-white shadow-lg font-semibold overflow-y-scroll">

                        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 my-2">
                            <div className="relative overflow-hidden">
                                {/* Background with gradient and subtle pattern */}
                                <div className="absolute inset-0 bg-gradient-to-r from-indigo-50 via-purple-50 to-pink-50 opacity-90" />
                                <div className="absolute inset-0 bg-grid-pattern opacity-5" /> {/* You'll need to add this pattern in your CSS */}

                                {/* Content Container */}
                                <div className="relative rounded-2xl border border-indigo-100 shadow-lg backdrop-blur-sm">
                                    <div className="px-6 py-3 sm:px-8 sm:py-3">
                                        {/* Decorative Elements */}
                                        <div className="absolute top-0 right-0 -mt-1 mr-4 hidden sm:block">
                                            <span className="text-4xl animate-bounce-slow">🚀</span>
                                        </div>

                                        {/* Main Content */}
                                        <div className="flex flex-col items-center text-center space-y-3">
                                            {/* Premium Badge */}
                                            {/* <div className="inline-flex items-center px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-sm font-semibold tracking-wide shadow-md">
                                                PREMIUM COMING SOON
                                            </div> */}

                                            {/* Heading */}
                                            <h2 className="text-xl sm:text-3xl font-bold text-gray-900 max-w-2xl leading-tight">
                                                We're Launching a
                                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600"> Paid Premium Access</span>
                                                {' '} with more case studies and practice questions!
                                            </h2>

                                            {/* Features List */}
                                            <div className="flex flex-wrap justify-center gap-2 text-gray-700">
                                                <div className="flex items-center space-x-2">
                                                    {/* <svg className="w-5 h-5 text-indigo-500" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                    </svg> */}
                                                    <span>Interested? Register below to be the first to know when it goes live!</span>
                                                </div>
                                            </div>

                                            {/* CTA Button */}
                                            <div className="mt-1">
                                                <a
                                                    href="/register"
                                                    className="inline-flex items-center px-6 py-2 rounded-lg bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-semibold shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-150 ease-in-out"
                                                >
                                                    Register Interest
                                                    <svg className="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                                    </svg>
                                                </a>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container mx-auto px-4 py-2"> {/* Reduced padding */}
                            <div className="space-y-1"> {/* Using space-y for consistent compact spacing */}
                                {/* First Heading Section */}
                                <div className="text-center">
                                    <h1 className="relative inline-block">
                                        <span className="absolute inset-0 -rotate-1 bg-gradient-to-r from-indigo-100 to-purple-100 rounded-lg transform -skew-y-1 opacity-40" />
                                        <span className="relative block text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight">
                                            Learn & Build{' '}
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
                                                SQL
                                            </span>{' '}
                                            expertise
                                        </span>
                                    </h1>
                                </div>

                                {/* Compact Divider */}
                                <div className="flex items-center justify-center py-1"> {/* Reduced padding */}
                                    <div className="h-px w-8 bg-gradient-to-r from-transparent via-gray-300 to-transparent" />
                                    <div className="mx-2">
                                        <svg className="w-4 h-4 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                                        </svg>
                                    </div>
                                    <div className="h-px w-8 bg-gradient-to-r from-transparent via-gray-300 to-transparent" />
                                </div>

                                {/* Second Heading Section */}
                                <div className="text-center">
                                    <h2 className="relative inline-block">
                                        {/* <span className="absolute bottom-0 left-0 w-full h-6 bg-indigo-100 -rotate-1 transform -skew-x-6" /> */}
                                        <span className="relative text-2xl sm:text-3xl lg:text-4xl font-bold leading-tight">
                                            Solve{' '}
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
                                                SQL interview
                                            </span>{' '}
                                            questions
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </div>



                        <div className="max-w-screen-xl mx-auto px-3 md:px-8  ">
                            <div className="max-w-lg">
                            </div>
                            <div className=" shadow-sm border rounded-lg overflow-x-auto ">
                                <table className="w-full table-auto text-sm text-left">
                                    <thead className="bg-gray-50 text-gray-600 font-medium border-b">
                                        <tr>
                                            <th className="py-3 px-4">Id</th>
                                            <th className="py-3 px-4">Question</th>
                                            <th className="py-3 px-4">Difficulty</th>
                                            <th className="py-3 px-4">Link</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 divide-y">
                                        {
                                            question_list.map((item, idx) => (
                                                <tr key={idx} >
                                                    <td className="text-center py-2  whitespace-pre-wrap">{item.id}</td>
                                                    <td className="px-2 py-2  whitespace-pre-wrap ">{item.Question}</td>
                                                    <td className="text-center py-2 whitespace-pre-wrap">{item.Difficulty}</td>
                                                    <td className="px-2 py-2 whitespace-nowrap">
                                                        {/* {item.Link} */}
                                                        <span className="px-3 py-2 rounded-full font-semibold text-xs text-blue-600 bg-blue-50">
                                                            {item.Link}
                                                        </span>

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Footer />

                    </div >

                </div >


            ) : (

                // Render this div for large screens
                <div className="flex flex-col  h-screen w-10/12" >

                    <div className="navbar bg-base-100 " >
                        <div className="navbar-start items-center">
                            <Link to="/" className="items-center flex flex-row">
                                <img src={logo} alt="Logo" className="w-1 sm:mx-auto" />
                            </Link>
                        </div>

                    </div>


                    <div className="flex flex-col h-full justify-items-center items-center  border border-gray-200 rounded-lg bg-white shadow-sm  font-semibold overflow-y-scroll" >


                        {/* <a
                            href="/register"
                            className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-2 mt-6 text-sm text-gray-700 bg-gray-100 rounded-full   hover:bg-gray-200 "
                            role="alert"
                        > */}
                        {/* <span className="text-xl bg-blue-500 rounded-full text-white px-4 py-1.5 mr-3">
                                New
                            </span>{" "} */}
                        <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-6 rounded-lg shadow-sm border border-blue-100 mb-2 mt-6">
                            <div className="flex flex-col items-center text-center space-y-3">
                                <div className="text-xl font-bold text-blue-800 flex items-center gap-2">
                                    <span>We're Launching a Paid Premium Version with more case studies and practice questions!</span>
                                    <span className="animate-bounce">🚀</span>
                                </div>

                                <div className="text-lg text-blue-600 flex items-center gap-2">
                                    <span className="animate-pulse">👉</span>
                                    <span>Interested? <a href="/register" className="font-semibold underline hover:text-blue-800 transition-colors">Register here</a> to be the first to know when it goes live!</span>
                                </div>
                            </div>
                        </div>
                        {/* <svg
                            className="ml-2 w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                            />
                        </svg> */}
                        {/* </a> */}





                        <div className="relative px-4 py-6 sm:py-6 mx-auto max-w-4xl">
                            {/* Main Content */}
                            <div className="text-center space-y-6">
                                {/* Primary Heading */}
                                <div className="relative">
                                    <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold tracking-tight text-gray-900">
                                        Learn & Build{' '}
                                        <span className="relative inline-block">
                                            <span className="relative z-10 bg-gradient-to-r from-indigo-500 to-blue-600 bg-clip-text text-transparent">
                                                SQL
                                            </span>
                                            <svg className="absolute -bottom-1 left-0 w-full h-2 text-indigo-200/60" viewBox="0 0 100 12" preserveAspectRatio="none">
                                                <path d="M0,0 Q50,12 100,0" stroke="currentColor" strokeWidth="4" fill="none" />
                                            </svg>
                                        </span>{' '}
                                        expertise from the basics
                                    </h1>
                                </div>

                                {/* Subtitle */}
                                <div className="max-w-2xl mx-auto">
                                    <p className="text-base sm:text-lg text-gray-600 leading-relaxed">
                                        Practice, Learn, and Master SQL! The most effective way to learn SQL is by practicing real-world queries and use cases.
                                    </p>
                                </div>

                                {/* Interview Questions Section */}
                                <div className="relative mt-8">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-200"></div>
                                    </div>
                                    <div className="relative flex justify-center">
                                        <div className="bg-white px-4">
                                            <h2 className="inline-flex items-center space-x-2 text-xl sm:text-2xl font-semibold text-gray-900">
                                                <span className="bg-gradient-to-r from-indigo-500 to-blue-600 bg-clip-text text-transparent">
                                                    SQL interview
                                                </span>
                                                <span>questions for practice</span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                {/* Visual Elements */}
                                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10 blur-3xl opacity-20">
                                    <div className="aspect-[1155/678] w-[36rem] bg-gradient-to-tr from-indigo-500 to-blue-500"
                                        style={{
                                            clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>




                        <Navbar />



                        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                            <div className="max-w-lg">
                            </div>
                            <div className="mt-2 shadow-sm border rounded-lg overflow-x-auto ">
                                <table className="w-full table-auto text-sm text-left">
                                    <thead className="bg-gray-50 text-gray-600 font-medium border-b">
                                        <tr>
                                            <th className="py-3 px-6">Id</th>
                                            <th className="py-3 px-6">Practice Question</th>
                                            <th className="py-3 px-6">Difficulty</th>
                                            <th className="py-3 px-6">Link</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 divide-y">
                                        {
                                            question_list.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td className="px-6 py-4 whitespace-pre-wrap">{item.id}</td>
                                                    <td className="px-6 py-4 whitespace-pre-wrap">{item.Question}</td>
                                                    <td className="px-6 py-4 whitespace-pre-wrap">{item.Difficulty}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {/* {item.Link} */}
                                                        <span className="px-3 py-2 rounded-full font-semibold text-xs text-blue-600 bg-blue-50">
                                                            {item.Link}
                                                        </span>

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <Footer />



                    </div >

                </div >
            )
            }






        </div >
    )
}

export default Home