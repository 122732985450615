import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";
import { FcPrevious, FcNext } from 'react-icons/fc';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react';


function Topbar() {

    const { id } = useParams();
    const navigate = useNavigate();
    const { logout, userId, firstName } = useContext(AuthContext);

    const handlePrevPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const prevPage = parseInt(id, 10) - 1;
        // Redirect to the next page
        if (prevPage < 1) {
            return;
        }
        window.location.href = `/subscribersmain/${prevPage}`;
    };

    const handleNextPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const nextPage = parseInt(id, 10) + 1;
        // Redirect to the next page
        if (nextPage >= 10000) {
            return;
        }
        window.location.href = `/subscribersmain/${nextPage}`;
    };

    const handleLogout = async () => {
        localStorage.clear();
        await logout();
        navigate("/");
    };


    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 shadow-sm relative z-50">
            <div className="flex justify-between items-center w-full">
                {/* Left side: Logo and Navigation */}
                <div className="flex items-center gap-2">
                    <Link to="/" className="flex-shrink-0">
                        <div className="flex items-center text-5xl font-extrabold">
                            SQL
                            <div className="relative bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded ms-2 flex items-center">
                                Guroo
                                {firstName ? `- ${firstName}` : ""}
                                <div className="absolute -top-3 -right-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-8 h-8"
                                        viewBox="0 0 24 24"
                                        style={{
                                            transform: "rotate(25deg)",
                                            color: "#1a1a1a"  // Darker color
                                        }}
                                    >
                                        {/* Main cap */}
                                        <path
                                            fill="currentColor"
                                            d="M12 2L1 7l11 5 11-5-11-5zm0 6.5l-8-3.64V15l8 3.64 8-3.64V4.86l-8 3.64z"
                                        />
                                        {/* Tassel */}
                                        <path
                                            d="M21 7L21.5 8L27.5 24"
                                            stroke="currentColor"
                                            strokeWidth="1.2"
                                            fill="none"
                                            strokeLinecap="round"
                                        />
                                        {/* Tassel knot */}
                                        <circle
                                            cx="21"
                                            cy="7"
                                            r="0.5"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </Link>

                    {/* Navigation Buttons */}
                    <div className="hidden sm:flex items-center gap-3 ml-4">
                        <button
                            onClick={handlePrevPage}
                            className="group flex items-center gap-1 px-3 py-1.5 bg-gradient-to-r from-gray-50 to-gray-100 hover:from-gray-100 hover:to-gray-200 text-gray-600 rounded-lg transition-all duration-300 border border-gray-200"
                            aria-label="Previous question"
                        >
                            <FcPrevious className="text-xl transition-transform group-hover:-translate-x-0.5" />
                            <span className="text-sm font-medium">Q.{parseInt(id, 10) - 1}</span>
                        </button>
                        <button
                            onClick={handleNextPage}
                            className="group flex items-center gap-1 px-3 py-1.5 bg-gradient-to-r from-gray-50 to-gray-100 hover:from-gray-100 hover:to-gray-200 text-gray-600 rounded-lg transition-all duration-300 border border-gray-200"
                            aria-label="Next question"
                        >
                            <span className="text-sm font-medium">Q.{parseInt(id, 10) + 1}</span>
                            <FcNext className="text-xl transition-transform group-hover:translate-x-0.5" />
                        </button>
                    </div>
                </div>

                {/* Right side: User Menu */}
                <div className="flex items-center">
                    <Menu as="div" className="relative ml-3">
                        <MenuButton className="flex items-center gap-2 p-2 rounded-full hover:bg-gray-100 transition-colors">
                            {/* User Avatar */}
                            <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center">
                                <span className="text-white text-sm font-medium">
                                    {/* Get first letter of user's name */}
                                    {localStorage.getItem('firstName')?.charAt(0) || 'U'}
                                </span>
                            </div>
                            {/* Dropdown Arrow */}
                            <svg className="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </MenuButton>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <MenuItems className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {/* User Info */}
                                <div className="px-4 py-2 border-b border-gray-100">
                                    <p className="text-sm font-medium text-gray-900">
                                        {localStorage.getItem('firstName') || 'User'}
                                    </p>
                                    <p className="text-xs text-gray-500">
                                        {localStorage.getItem('username') || 'user@example.com'}
                                    </p>
                                </div>

                                {/* Menu Items */}
                                <MenuItem>
                                    {({ active }) => (
                                        <Link
                                            to="/profile"
                                            className={`${active ? 'bg-gray-100' : ''
                                                } flex items-center px-4 py-2 text-sm text-gray-700`}
                                        >
                                            <svg className="mr-3 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                />
                                            </svg>
                                            Profile
                                        </Link>
                                    )}
                                </MenuItem>

                                <MenuItem>
                                    {({ active }) => (
                                        <Link
                                            to="/settings"
                                            className={`${active ? 'bg-gray-100' : ''
                                                } flex items-center px-4 py-2 text-sm text-gray-700`}
                                        >
                                            <svg className="mr-3 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                            Settings
                                        </Link>
                                    )}
                                </MenuItem>

                                <div className="border-t border-gray-100">
                                    <MenuItem>
                                        {({ active }) => (
                                            <button
                                                onClick={handleLogout}

                                                className={`${active ? 'bg-gray-100' : ''
                                                    } flex w-full items-center px-4 py-2 text-sm text-red-700`}
                                            >
                                                <svg className="mr-3 h-5 w-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                                    />
                                                </svg>
                                                Sign out
                                            </button>
                                        )}
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </nav>

    );
}

export default Topbar;
