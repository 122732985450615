import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        // Handler to close menu when clicking outside
        function handleClickOutside(event) {
            if (isMenuOpen &&
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }

        // Add event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isMenuOpen]);

    const navItems = [
        { to: "/", label: "Home" },
        { to: "/learnsql/1", label: "Learn SQL" },
        { to: "/question/1", label: "Practice SQL" },
        { to: "/pricing", label: "Pricing" },
        { to: "https://sqlguroo.com/blog/blog", label: "Blog" }
    ];

    return (
        <nav className="fixed top-3 w-full px-4 z-50">
            <div className="max-w-7xl mx-auto">
                {/* Main Navbar Container */}
                <div className="group relative flex items-center justify-between rounded-3xl border border-slate-200 bg-slate-100/60 backdrop-blur-lg shadow-lg shadow-black/5">
                    {/* Logo */}
                    <div className="flex items-center pl-4">
                        <Link to="/">
                            <p className="text-xl sm:text-2xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">
                                SQL Guroo
                            </p>
                        </Link>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center space-x-2">
                        {navItems.map((item) => (
                            <Link key={item.to} to={item.to}>
                                <button className="px-3 py-2 rounded-lg text-slate-600 hover:text-black font-bold hover:bg-white/30 transition-colors">
                                    {item.label}
                                </button>
                            </Link>
                        ))}
                    </div>

                    {/* Desktop Login Button */}
                    <div className="hidden md:block pr-2">
                        <Link to="/login">
                            <button className="relative h-9 px-4 rounded-full bg-gradient-to-b from-indigo-400 to-indigo-500 hover:from-indigo-500 hover:to-indigo-600 transition-all duration-200">
                                <div className="flex items-center space-x-2 text-white font-bold">
                                    <span>Login</span>
                                    <svg className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                    </svg>
                                </div>
                            </button>
                        </Link>
                    </div>

                    {/* Mobile Menu Button */}
                    <div className="md:hidden pr-4">
                        <button
                            ref={buttonRef}
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            className="p-2 rounded-lg hover:bg-white/30 transition-colors"
                        >
                            {!isMenuOpen ? (
                                <svg className="w-6 h-6 text-slate-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            ) : (
                                <svg className="w-6 h-6 text-slate-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>

                {/* Mobile Menu Dropdown */}
                {isMenuOpen && (
                    <div ref={menuRef} className="md:hidden mt-2 rounded-xl border border-slate-200 bg-slate-100/60 backdrop-blur-lg shadow-lg overflow-hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navItems.map((item) => (
                                <Link key={item.to} to={item.to} className="block">
                                    <button
                                        className="w-full text-left px-4 py-2 text-base font-bold text-slate-600 hover:text-black hover:bg-white/30 rounded-lg transition-colors"
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        {item.label}
                                    </button>
                                </Link>
                            ))}
                            {/* Mobile Login Button */}
                            <Link to="/login" className="block">
                                <button
                                    className="w-full mt-2 px-4 py-2 rounded-lg bg-gradient-to-b from-indigo-400 to-indigo-500 text-white font-bold hover:from-indigo-500 hover:to-indigo-600 transition-all duration-200"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    Login
                                </button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
